import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import UIReducers from './ui';
import UserReducer from './user';
import FormReducer from './form';
import AdminReducer from './admin';


const rootReducers = history => combineReducers({
  ui: UIReducers,
  user: UserReducer,
  form: FormReducer,
  admin: AdminReducer,
  router: connectRouter(history),
});

export default rootReducers;
