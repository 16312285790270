import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { actions as adminActions } from 'reducers/admin';
import { API } from '../../config/constants/api';

/* GET TEXTS */
export const getEntity = data => (
  axios({
    method: 'POST',
    url: API.FORM.GET_ENTITY_ROWS,
    withCredentials: true,
    data,
  })
)
export const updateEntityFetch = (data) => {
  return axios({
    method: 'POST',
    url: API.FORM.UPDATE_ENTITY,
    withCredentials: true,
    data,
  })
}
/* SET PRIVILEGES */
export const setPrivilegesFetch = data => (
  axios({
    data,
    method: 'POST',
    url: API.ADMIN.SET_PRIVILEGES,
    withCredentials: true,
  })
)
export function* setPrivileges({ data }) {
  try {
    yield call(setPrivilegesFetch, data);
    // yield put(adminActions.getPrivilegesById(data.idUser));
  } catch (err) {
    console.log(err);
  }
}
/* GET PRIVILEGES */
export const getPrivilegesFetch = data => (
  axios({
    data,
    method: 'POST',
    url: API.ADMIN.GET_PRIVILEGES,
    withCredentials: true,
  })
)
export function* getPrivileges({ idUser }) {
  try {
    const responseAux = yield call(getPrivilegesFetch, { idUser });
    const { data } = responseAux;
    const { all, user } = data;
    const newAll = [];
    // we must remove from all those what are in usere
    all.forEach((item) => {
      // check if user have it
      const userHasItem = user.find(subItem => subItem.idSection === item.idSection);
      if (!userHasItem) {
        newAll.push(item);
      }
    });
    data.all = newAll;
    yield put(adminActions.setPrivilegesById({
      res: data,
      operation: 'success',
      infoOp: null,
    }))
  } catch (err) {
    yield put(adminActions.setPrivilegesById({
      res: null,
      operation: 'error',
      infoOp: null,
    }))
  }
}
/* GET USER */
export const getUserFetch = () => (
  axios({
    method: 'GET',
    url: API.ADMIN.GET_USERS,
    withCredentials: true,
  })
)
export function* getUsers() {
  try {
    const responseAux = yield call(getUserFetch);
    const { data } = responseAux;
    if (data && data.res) {
      yield put(adminActions.setUsers({
        res: data.res,
        operation: 'success',
        infoOp: data.info,
      }))
    }
    if (data && data.error) {
      yield put(adminActions.setUsers({
        res: null,
        operation: 'error',
        infoOp: data.info,
      }))
    }
  } catch (err) {
    yield put(adminActions.setUsers(false));
  }
}
/* NEW USER */
export const newUserFetch = data => (
  axios({
    data,
    method: 'POST',
    url: API.ADMIN.ADD_NEW_USER,
    withCredentials: true,
  })
)
export function* createNewUser({ params }) {
  try {
    const responseAux = yield call(newUserFetch, { ...params });
    const { data } = responseAux;
    if (data && data.error) {
      yield put(adminActions.createNewUserResult({
        res: 'error',
        infoOp: data.error,
      }))
    }
    if (data && data.info) {
      yield put(adminActions.createNewUserResult({
        res: 'success',
        infoOp: data.info,
      }))
    }
  } catch (err) {
    yield put(adminActions.createNewUserResult(false));
  }
}

/* DELETE USER */
export const deleteUserFetch = data => (
  axios({
    data,
    method: 'POST',
    url: API.ADMIN.DELETE_USER,
    withCredentials: true,
  })
)

export function* deleteUser(params) {
  try {
    const { id } = params;
    const responseAux = yield call(deleteUserFetch, { id });
    const { infoOp, operation } = responseAux.data;
    yield put(adminActions.setDeleteUserResult({
      infoOp,
      operation,
    }))
  } catch (err) {
    yield put(adminActions.setDeleteUserResult({
      infoOp: 'Se ha producido un error inesperado',
      operation: 'error',
    }))
  }
}

/* SECTIONS */
export const sectionsFetch = () => {
  return axios({
    method: 'GET',
    url: API.ADMIN.GET_SECTIONS,
    withCredentials: true,
  })
}
export function* getSections({ params }) {
  try {
    const responseAux = yield call(sectionsFetch, params);
    const sections = responseAux.data;
    const sections2App = {};

    sections.forEach((itemSection) => {
      const { mainName } = itemSection;
      if (!sections2App[mainName]) {
        sections2App[mainName] = [];
      }
      sections2App[mainName].push(itemSection);
    })

    yield put(adminActions.getSectionsOK(sections2App))
  } catch (err) {
    yield put(adminActions.getSectionsKO());
  }
}
