import { setCookie, getCookie } from '../utils/commonUtils';

export const types = {
  /* COMMONS FOR EVERY WEB */
  SET_HEADER_VISIBILITY: 'UI/SET_HEADER_VISIBILITY',
  SET_LANGUAGE: 'UI/SET_LANGUAGE',
  SET_COOKIES_ACCEPTED: 'UI/SET_COOKIES_ACCEPTED',
  SEND_MAIL: 'UI/SEND_MAIL',
  SET_MAIL_RESULT: 'UI/SET_MAIL_RESULT',
  /* CLIENT */
  GET_MAIN_INFO: 'UI/GET_MAIN_INFO',
  SET_MAIN_INFO: 'UI/SET_MAIN_INFO',
}

export const initialState = {
  /* COMMONS */
  cookiesAccepted: getCookie('cookiesAccepted') ? getCookie('cookiesAccepted') : 'n',
  headerVisible: true,
  contactMail: null,
  /* CLIENT */
  mainInfo: null,

}

const UIReducer = (state = initialState, action) => {
  switch (action.type) {
    /* COMMON */
    case types.SET_COOKIES_ACCEPTED: {
      setCookie('cookiesAccepted', 'y', 365);
      return {
        ...state,
        cookiesAccepted: 'y',
      }
    }
    case types.SET_LANGUAGE: {
      const { language } = state;
      const newLanguageValue = language === 'es' ? 'en' : 'es';
      setCookie('language', newLanguageValue, 365);
      return {
        ...state,
        language: newLanguageValue,
      }
    }
    case types.SEND_MAIL: return {
      ...state,
      contactMail: {
        infoOp: '',
        operation: 'loading',
      },
    }
    case types.SET_MAIL_RESULT: return {
      ...state,
      contactMail: action.result,
    }
    case types.SET_HEADER_VISIBILITY: { return { ...state, headerVisible: action.payload } }
    /* CLIENT */
    case types.GET_MAIN_INFO: {
      return {
        ...state,
        mainInfo: {
          infoOp: 'fetching',
          data: null,
        },
      }
    }
    case types.SET_MAIN_INFO: {
      return {
        ...state,
        mainInfo: {
          infoOp: action.response.infoOp,
          data: action.response.data,
        },
      }
    }
    default: return state
  }
}

export const actions = {
  /* CLIENT */
  getMainInfo: () => ({ type: types.GET_MAIN_INFO }),
  setMainInfo: response => ({ type: types.SET_MAIN_INFO, response }),
  /* COMMON */
  setHeaderVisibility: flag => ({ type: types.SET_HEADER_VISIBILITY, payload: flag }),
  setLanguage: () => ({ type: types.SET_LANGUAGE }),
  setCookiesAccepted: () => ({ type: types.SET_COOKIES_ACCEPTED }),
  sendEmail: data => ({ type: types.SEND_MAIL, data }),
  setEmailResult: result => ({ type: types.SET_MAIL_RESULT, result }),
}

export default UIReducer;
