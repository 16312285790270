import axios from 'axios';
import merge from 'deepmerge';
import {
  call,
  put,
  all,
  fork,
  take,
  select,
} from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import { actions as formActions } from 'reducers/form';
import { API } from '../../config/constants/api';


/* Global */
export const cleanValuesFromState = (data) => {
  const dataFinal = {};
  const dataKeys = Object.keys(data);
  // FIRST LEVEL CLEAN
  dataKeys.forEach((key) => {
    if (
      key !== 'formFilled'
      && key !== 'infoOp'
      && key !== 'operation'
      && key !== 'entityData'
      && key !== 'formType'
      && key !== 'buttonVisible'
      && key !== 'entityRows'
      && key !== 'fieldArray'
      && key !== 'deleteCheck'
      && key !== 'entityValues'
      && key !== 'inputsGaps'
    ) {
      dataFinal[key] = data[key];
    }
  });
  // checkking data inside data (the important one)
  if (dataFinal && dataFinal.data) {
    const dataInputKeys = Object.keys(data.data);
    dataInputKeys.forEach((key) => {
      if (dataFinal.data[key] && Array.isArray(dataFinal.data[key])) {
        dataFinal.data[key] = dataFinal.data[key].filter(item => item != null);
      }
    })
  }
  return dataFinal;
}

// FILES
// FILES DELETE
export const deleteFetch = (pathArray) => {
  return axios({
    method: 'POST',
    url: API.FORM.DELETE_FILE,
    withCredentials: true,
    data: {
      pathArray,
    },
  })
}

export function* deleteSingleFile(fileName) {
  yield call(deleteFetch, fileName);
}

export function* deleteFiles(pathArray) {
  return yield all(
    pathArray.map(fileName => deleteSingleFile(fileName))
  );
}
// FILES UPLOAD

export const upload = (files, kindOfFile, onUploadProgress) => {
  const data = new FormData();
  let numberOfFiles = 0;
  [...files].map((file, index) => {
    const aux = `file${index}`;
    numberOfFiles += 1;
    return data.append(aux, file);
  });
  data.append('numberOfFiles', numberOfFiles);
  data.append('kind', kindOfFile);

  return axios.post(API.FORM.UPLOAD_FILE, data, { onUploadProgress });
};

function createUploader(files, kindOfFile) {
  let emit;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {};
  });
  const uploadProgressCb = ({ total, loaded }) => {
    const percentage = Math.round((loaded * 100) / total);
    emit(percentage);
    if (percentage === 100) emit(END);
  };
  const uploadPromise = upload(files, kindOfFile, uploadProgressCb);
  return [uploadPromise, chan];
}

function* uploadProgressWatcher(chan) {
  while (true) { // eslint-disable-line no-constant-condition
    const progress = yield take(chan);
    yield put(formActions.setUploadPercentage(progress));
  }
}
/* GET UPLOADED FILES */
export const uploadedFilesFromState = state => state.form.uploadedFiles;

export function* uploadFiles({ data }) {
  yield put(formActions.setUploadPercentage(0));
  try {
    const { files, uploadedFiles, update, kindOfFile } = data;
    let response = { data: null };
    console.log(response);
    if (files && files.length > 0) {
      const [uploadPromise, chan] = yield call(createUploader, files, kindOfFile);
      yield fork(uploadProgressWatcher, chan);
      response = yield call(() => uploadPromise);
    }

    // second of all delete\\\ si no es actualización borro
    if (!update && uploadedFiles && uploadedFiles.length > 0) {
      yield call(deleteFiles, uploadedFiles);
    }
    // si es actualización combino los subidos de antes con os de ahora
    const newStructure = { }
    newStructure[kindOfFile] = (response.data && response.data.data) || [];
    if (kindOfFile === 'imagen' && response.data && response.data.imagesData) {
      const keysImagesData = Object.keys(response.data.imagesData);
      newStructure.imagen_data = [];
      keysImagesData.forEach((imagesDataKey) => {
        const currentItem = response.data.imagesData[imagesDataKey];
        newStructure.imagen_data.push(currentItem);
      });
    }

    yield put(formActions.addUploadfiles({
      data: newStructure,
      infoOp: 'Se han subido los ficheros con éxito',
      operation: 'success',
    }));
  } catch (e) {
    yield put(formActions.setUploadfiles({
      infoOp: 'Ocurrió algún error',
      operation: 'error',
    }));
  }
}


export function* deleteUploadedFile({ data }) {
  try {
    const { fileName, kindOfFile } = data;
    if (kindOfFile === 'imagen') {
      const tokkens = fileName.split('/');
      const maxIndex = tokkens.length - 1;
      const lastTokken = `thumb_${tokkens[maxIndex]}`;
      tokkens[maxIndex] = lastTokken;
      const finalThumbName = tokkens.join('/');
      console.log(finalThumbName);
      yield call(deleteFetch, finalThumbName);
    }
    yield call(deleteFetch, fileName);
    const filesUploaded = yield select(uploadedFilesFromState);
    const newFilesUploaded = [...filesUploaded[kindOfFile]];
    let newImagesData = [];
    if (kindOfFile === 'imagen') {
      newImagesData = [...filesUploaded.imagen_data]
    }

    const index = newFilesUploaded.indexOf(fileName);
    if (index > -1) {
      newFilesUploaded.splice(index, 1);
      if (kindOfFile === 'imagen') {
        newImagesData.splice(index, 1);
      }
    }

    const finalFiles = { ...filesUploaded }
    finalFiles[kindOfFile] = newFilesUploaded;
    if (kindOfFile === 'imagen') {
      finalFiles.imagen_data = newImagesData;
    }

    yield put(formActions.setUploadfiles({
      data: finalFiles,
      infoOp: 'Se han subido los ficheros con éxito',
      operation: 'success',
    }));
  } catch (e) {
    console.log(e);
  }
}

// DELETE
export const deleteEntityFetch = (data) => {
  return axios({
    method: 'POST',
    url: API.FORM.DELETE_ENTITY,
    withCredentials: true,
    data,
  })
}
export function* deleteEntity({ params }) {
  try {
    const dataFinal = cleanValuesFromState(params);
    const response = yield call(deleteEntityFetch, dataFinal);
    yield put(formActions.setDeleteEntityResult(response.data));
  } catch (err) {
    console.log(err);
    yield put(formActions.setDeleteEntityResult({
      infoOp: 'Se ha producido un error inesperado',
      operation: 'error',
    }));
  }
}

// UPDATE
export const updateEntityFetch = (data) => {
  return axios({
    method: 'POST',
    url: API.FORM.UPDATE_ENTITY,
    withCredentials: true,
    data,
  })
}

export function* updateEntity({ params }) {
  try {
    const dataFinal = cleanValuesFromState(params);
    const response = yield call(updateEntityFetch, dataFinal);
    yield put(formActions.setUpdateEntityResult(response.data));
  } catch (err) {
    yield put(formActions.setUpdateEntityResult({
      infoOp: 'Se ha producido un error inesperado',
      operation: 'error',
    }));
  }
}
// SHOW
export const showEntityFetch = (data) => {
  return axios({
    method: 'POST',
    url: API.FORM.GET_ENTITY_ROWS,
    withCredentials: true,
    data,
  })
}
export function* getEntityRows(data) {
  try {
    const response = yield call(showEntityFetch, data.data);
    yield put(formActions.setEntityRows(response.data.data));
  } catch (err) {
    yield put(formActions.setEntityRows(null));
  }
}
// INSERT
export const insertEntityFetch = (data) => {
  return axios({
    method: 'POST',
    url: API.FORM.INSERT_ENTITY,
    withCredentials: true,
    data,
  })
}
export function* insertEntity({ data }) {
  try {
    const dataFinal = cleanValuesFromState(data);
    const response = yield call(insertEntityFetch, dataFinal);
    yield put(formActions.insertEntityResult(response.data));
  } catch (err) {
    yield put(formActions.insertEntityResult({
      infoOp: 'Se ha producido un error inesperado',
      operation: 'error',
    }));
  }
}
// COMMONS
export const formFetch = (data) => {
  return axios({
    method: 'POST',
    url: API.FORM.GET_ENTITY_INFO,
    withCredentials: true,
    data,
  })
}
export function* getEntity({ params }) {
  try {
    const responseAux = yield call(formFetch, { entity: params });
    const response = responseAux.data;
    const { data, error } = response;
    if (error) {
      yield put(formActions.getEntityKO());
    } else {
      yield put(formActions.getEntityOK({ type: params, data }));
    }
  } catch (err) {
    yield put(formActions.getEntityKO());
  }
}

// CREATE PDF
export const createEntityPdfFetch = (data) => {
  return axios({
    method: 'POST',
    url: API.FORM.CREATE_ENTITY_PDF,
    withCredentials: true,
    data,
  })
}
export function* createEntityPdf({ params }) {
  try {
    const responseAux = yield call(createEntityPdfFetch, { 
      entity: params.entityName,
      id: params.entityIdValue,
    });
    const response = responseAux.data;
    if (response.operation === 'success') {
      yield put(formActions.checkEntityPDF(params));
    }
    yield put(formActions.createEntityPDFResult(response));
  } catch (err) {
    console.log(err);
    yield put(formActions.createEntityPDFResult({
      infoOp: 'Ha ocurrido un error inesperado',
      operation: 'error',
    }));
  }
}

// CHECK ENTITY PDF
export const checkEntityPdfFetch = (data) => {
  return axios({
    method: 'POST',
    url: API.FORM.CHECK_ENTITY_PDF,
    withCredentials: true,
    data,
  })
}

export function* checkEntityPdf({ params }) {
  try {
    const responseAux = yield call(checkEntityPdfFetch, { 
      entity: params.entityName,
      id: params.entityIdValue,
    });
    yield put(formActions.setCheckEntityPDFResult(responseAux.data));
  } catch(err) {
    yield put(formActions.setCheckEntityPDFResult({
      infoOp: 'Error inesperado, contacte con su webmaster',
      kind: 'error'
    }));
  }
}


