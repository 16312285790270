/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import Slider from 'react-slick';
import reactHtmlParser from 'react-html-parser';
import { shuffle } from '../../utils/commonUtils';
import Logo from '../../assets/images/logoBlanco.svg';
import Fake from 'react-loading-skeleton';
import './PictureHead.scss';

class PictureHead extends Component {

  shouldComponentUpdate = (nextProps, nextState) => {
    return this.props.status !== nextProps.status || this.props.visible !== nextProps.visible;
  }

  render() {
    const { status, visible, info } = this.props;
    if (!visible) {
      return null;
    }
  
    if (status === 'fetching') {
      return <Fake width="100%" height={350} />;
    }
  
    if (!info || !info.cabecera) {
      return null;
    }

    let { cabecera } = info;
    cabecera = shuffle(cabecera)
    const { imagenes } = info;
    const settingsForSlide = {
      accessibility: true,
      className: 'slider',
      arrows: false,
      centerMode: false,
      variableWidth: false,
      slidesToShow: 1,
      useCSS: true,
      useTransform: true,
      slidesToScroll: 1,
      swipe: false,
      pauseOnHover: true,
      pauseOnFocus: true,
      autoplay: true,
      autoplaySpeed: 10000,
      dots: false,
      speed: 1500,
      fade: true,
      lazyLoad: true,
      infinite: true,
    };
    return (
      <div key="PictureHead" className="PictureHead">
        <div className="container LogoContainer">
          <img src={Logo} alt="logo" />
        </div>
        <div className="Slider">
          <Slider {...settingsForSlide}>
            {cabecera && cabecera.map((item, index) => {
              const { nombre, contenido, imagen } = item;
              const myPic = imagenes.find(img => img.idImagen === imagen);
              return (
                <div key={`PictureHead_Item-${index.toString()}`} className="PictureHead_Item">
                  <div className="background_wrapper" style={{backgroundImage: `url(${myPic.src})`}}>
                    <div className="container">
                      <div className="info">
                        {contenido && (<div className="sentence italic">{reactHtmlParser(contenido)}</div>)}
                        {nombre && (<div className="author regular">{reactHtmlParser(nombre)}</div>)}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    )
  }
}


export default PictureHead;
