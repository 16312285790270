import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { whichAnimationEvent } from 'utils/stylesUtils';
import './Modal.scss';

const modalRoot = document.getElementById('modal-root')

export const OPACITY_LAYOUT_COLORS = {
  BLACK: 'black',
  WHITE: 'white',
  BLACK05: 'black05',
  TRANSPARENT: 'transparent',
}
// XPOSITION_ YPOSITION
export const CONTENT_POSITION = {
  // absolutelly center
  CENTER_CENTER: 'center--center',
  LEFT_CENTER: 'left--center',
  RIGHT_CENTER: 'right--center',
  // vertically top
  CENTER_TOP: 'center--top',
  RIGHT_TOP: 'right--top',
  LEFT_TOP: 'left--top',
  // vertically bottom
  CENTER_BOTTOM: 'center--bottom',
  RIGHT_BOTTOM: 'right--bottom',
  LEFT_BOTTOM: 'left--bottom',
}

export const IN_SCENE_EFFECT = {
  FADE_IN: 'fadeIn',
  FADE_IN_LEFT: 'fadeInLeft',
  FADE_IN_RIGHT: 'fadeInRight',
  FADE_IN_TOP: 'fadeInTop',
  FADE_IN_BOTTOM: 'fadeInBottom',
}

export const OUT_SCENE_EFFECT = {
  FADE_OUT: 'fadeOut',
  FADE_OUT_LEFT: 'fadeOutLeft',
  FADE_OUT_RIGHT: 'fadeOutRight',
  FADE_OUT_TOP: 'fadeOutTop',
  FADE_OUT_BOTTOM: 'fadeOutBottom',
}


class SgnModal extends Component {
  static show() { SgnModal.__singletonRef.__show();}
  static hide() {SgnModal.__singletonRef.__hide();}
  static setContent(component) {SgnModal.__singletonRef.__setContent(component);}
  static setModalProps(props) {SgnModal.__singletonRef.__setModalProps(props);}

  constructor(props) {
    super(props);
    const {
      opacityLayoutColor,
      contentPosition,
      className,
      inSceneEffect,
      outSceneEffect,
      closeByClickingOutside,
    } = props;
    SgnModal.__singletonRef = this;

    this.state = {
      unMounted: true,
      show: false,
      className,
      inSceneEffect,
      outSceneEffect,
      contentPosition,
      opacityLayoutColor,
      closeByClickingOutside,
    }
  }

  /* this handle transition and unmounted event */
  componentDidUpdate = () => {
    const { show, unMounted } = this.state;
    if (!show && !unMounted) {
      const transitionEvent = whichAnimationEvent();
      const element = document.getElementById('animationReference');
      if (transitionEvent) {
        element.addEventListener(transitionEvent, () => {
          this.setState({ unMounted: true })
        });
      }
    }
  }

  createContentCss = (contentPosition, inSceneEffect, outSceneEffect, show, className) => {
    const cssContentPosition = contentPosition ? `Modal_content Modal_content--${contentPosition}` : '';
    let sceneCss = inSceneEffect ? ` Modal_content--${contentPosition}--${inSceneEffect}` : '';
    if (!show) {
      sceneCss = outSceneEffect ? ` Modal_content--${contentPosition}--${outSceneEffect}` : '';
    }
    const cssOwnClassName = className ? ` ${className}` : '';
    return `${cssContentPosition}${sceneCss}${cssOwnClassName}`;
  }

  createOpacityLayoutCss = (opacityLayoutColor, show) => {
    let cssOpacityLayout = `Single_Modal opacity_layout opacity_layout--${opacityLayoutColor} Single_Modal--fadeIn`;
    if (!show) {
      cssOpacityLayout = `Single_Modal opacity_layout opacity_layout--${opacityLayoutColor} Single_Modal--fadeOut`;
    }
    return cssOpacityLayout;
  }

  handleCloseOnLayout = () => {
    const { closeByClickingOutside } = this.state;
    if (closeByClickingOutside) {
      const myInstance = this.constructor;
      myInstance.hide();
    }
  }


  __setContent(content) { this.setState({ content }) }

  __setModalProps(props) { this.setState({ ...props }) }

  __show() {
    // disallow scroll on html,body
    const body = document.getElementsByTagName('body')[0];
    body.style.overflowY = 'hidden';
    const html = document.getElementsByTagName('html')[0];
    html.style.overflowY = 'hidden';
    // set properties
    this.setState({ show: true, unMounted: false, })
  }

  __hide() {
    // disallow scroll on html,body
    const body = document.getElementsByTagName('body')[0];
    body.style.overflowY = 'auto';
    const html = document.getElementsByTagName('html')[0];
    html.style.overflowY = 'auto';
    // set properties
    this.setState({ show: false })
  }

  render() {
    const { 
      opacityLayoutColor,
      contentPosition,
      className,
      inSceneEffect,
      outSceneEffect,
      unMounted,
    } = this.state;

    const { show, content } = this.state;
    const cssOpaticyLayout = this.createOpacityLayoutCss(opacityLayoutColor, show);
    const contentCss = this.createContentCss(
      contentPosition,
      inSceneEffect,
      outSceneEffect,
      show,
      className,
    );

    if ((!show && unMounted) || !content) { return null; }


    return ReactDOM.createPortal(
      <Fragment>
        <div className={`${contentCss}`}>
          {content}
        </div>
        <div
          id="animationReference"
          role="button"
          tabIndex="-1"
          className={cssOpaticyLayout}
          onClick={this.handleCloseOnLayout}
          onKeyPress={this.handleCloseOnLayout}
        />
      </Fragment>,
      modalRoot,
    )
  }
}

SgnModal.propTypes = {
  className: PropTypes.string,
  closeByClickingOutside: PropTypes.bool,
  contentPosition: PropTypes.oneOf([
    CONTENT_POSITION.CENTER_CENTER,
    CONTENT_POSITION.CENTER_TOP,
    CONTENT_POSITION.RIGHT_TOP,
    CONTENT_POSITION.LEFT_TOP,
    CONTENT_POSITION.CENTER_BOTTOM,
  ]),
  opacityLayoutColor: PropTypes.oneOf([
    OPACITY_LAYOUT_COLORS.BLACK,
    OPACITY_LAYOUT_COLORS.WHITE,
    OPACITY_LAYOUT_COLORS.BLACK05,
    OPACITY_LAYOUT_COLORS.TRANSPARENT,
  ]),
  inSceneEffect: PropTypes.oneOf([
    IN_SCENE_EFFECT.FADE_IN,
    IN_SCENE_EFFECT.FADE_IN_LEFT,
    IN_SCENE_EFFECT.FADE_IN_RIGHT,
    IN_SCENE_EFFECT.FADE_IN_TOP,
    IN_SCENE_EFFECT.FADE_IN_BOTTOM,
  ]),
  outSceneEffect: PropTypes.oneOf([
    OUT_SCENE_EFFECT.FADE_OUT,
    OUT_SCENE_EFFECT.FADE_OUT_LEFT,
    OUT_SCENE_EFFECT.FADE_OUT_RIGHT,
    OUT_SCENE_EFFECT.FADE_OUT_BOTTOM,
    OUT_SCENE_EFFECT.FADE_OUT_TOP,
  ]),
}

SgnModal.defaultProps = {
  className: '',
  closeByClickingOutside: false,
  contentPosition: CONTENT_POSITION.CENTER_BOTTOM,
  opacityLayoutColor: OPACITY_LAYOUT_COLORS.TRANSPARENT,
  inSceneEffect: IN_SCENE_EFFECT.FADE_IN,
  outSceneEffect: OUT_SCENE_EFFECT.FADE_OUT,
}

export default SgnModal;