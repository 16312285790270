import { howAppIsRunning } from '../../src/utils/commonUtils';
import { DEV, BASE_WEBSITE_URL } from './constants';

export let baseApiUrl = howAppIsRunning() === DEV ? 'http://127.0.0.1/websiteseed/public/api/' : `//${BASE_WEBSITE_URL}/api/`;

export const API = {
  USER: {
    LOGIN: `${baseApiUrl}user/login.php`,
    CHANGE_PASSWORD: `${baseApiUrl}user/changepassword.php`,
    CLOSE_SESSION: `${baseApiUrl}user/closeSession.php`,
    SEND_RECOVER_EMAIL: `${baseApiUrl}user/sendRecoverMail.php`,
    RESET_PASSWORD: `${baseApiUrl}user/resetpassword.php`,
    SEND_MAIL: `${baseApiUrl}common/mail.php`,
  },
  SEO: {
    GET: `${baseApiUrl}seo/getSeo.php`,
  },
  FORM: {
    UPLOAD_IMAGE: `${baseApiUrl}formPanel/img/imageHandler.php`,
    UPLOAD_FILE: `${baseApiUrl}formPanel/file/fileHandler.php`,
    DELETE_FILE: `${baseApiUrl}formPanel/file/deleteFiles.php`,
    GET_ENTITY_ROWS: `${baseApiUrl}formPanel/getEntityRows.php`,
    DELETE_ENTITY: `${baseApiUrl}formPanel/deleteEntity.php`,
    UPDATE_ENTITY: `${baseApiUrl}formPanel/updateEntity.php`,
    INSERT_ENTITY: `${baseApiUrl}formPanel/insertPanel.php`,
    GET_ENTITY_INFO: `${baseApiUrl}formPanel/formPanel.php`,
    CREATE_ENTITY_PDF: `${baseApiUrl}formPanel/createEntityPdf.php`,
    CHECK_ENTITY_PDF: `${baseApiUrl}formPanel/checkEntityPdf.php`,
  },
  ADMIN: {
    GET_PRIVILEGES: `${baseApiUrl}admin/user/getPrivileges.php`,
    SET_PRIVILEGES: `${baseApiUrl}admin/user/setPrivileges.php`,
    GET_USERS: `${baseApiUrl}admin/user/getUsers.php`,
    DELETE_USER: `${baseApiUrl}admin/user/deleteUser.php`,
    ADD_NEW_USER: `${baseApiUrl}admin/user/addNewUser.php`,
    GET_SECTIONS: `${baseApiUrl}admin/getSections.php`,
  },
}
