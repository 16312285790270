import axios from 'axios';
import { call, put, select, all, delay } from 'redux-saga/effects';
import { actions as uiActions } from 'reducers/ui';
import { API } from '../../config/constants/api';

/* COMMON FOR EVERY WEB */
export const getEntity = data => (
  axios({
    method: 'POST',
    url: API.FORM.GET_ENTITY_ROWS,
    withCredentials: true,
    data,
  })
)

export const mailFetch = data => (
  axios({
    method: 'POST',
    url: API.USER.SEND_MAIL,
    withCredentials: true,
    data,
  })
)
export function* sendMail({ data }) {
  try {
    const response = yield call(mailFetch, data);
    const { infoOp, operation } = response.data;
    yield put(uiActions.setEmailResult({
      infoOp,
      operation,
    }));
  } catch (err) {
    yield put(uiActions.setEmailResult({
      infoOp: '',
      operation: 'loading',
    }))
  }
}

function* loopGetEntity(reqData) {
  const { finalName } = reqData;
  const response = yield call(getEntity, reqData);
  return {
    finalName,
    data: response.data.data,
  };
}


/* CLIENT THINGS */
export const getMainInfoState = state => state.ui.mainInfo;

export function* getMainInfo() {
  try {
    const lastMainInfo = yield select(getMainInfoState);
    if (!lastMainInfo || !lastMainInfo.data) {
      const allMyRequest = [];
      const allDataMapped = {};
      const cabeceras = {
        finalName: 'cabecera',
        entity: 'cabecera',
        fieldArray: ['idCabecera', 'nombre', 'contenido', 'imagen'],
      }
      const noticias = {
        finalName: 'noticias',
        entity: 'noticias',
        fieldArray: ['idNoticia', 'titulo', 'contenido', 'fecha', 'imagen', 'archivo'],
        append: 'ORDER BY idNoticia DESC',
      }
      const orientacion = {
        finalName: 'orientacion',
        entity: 'orientacion',
        fieldArray: ['idOrientacion', 'titulo', 'contenido', 'fecha', 'archivo', 'imagen'],
        append: 'ORDER BY idOrientacion DESC',
      }
      const anuncios = {
        finalName: 'anuncios',
        entity: 'anuncios',
        fieldArray: ['idAnuncio', 'titulo', 'contenido', 'fecha', 'archivo'],
        append: 'ORDER BY idAnuncio DESC',
      }
      const imagenes = {
        finalName: 'imagenes',
        entity: 'imagen',
        fieldArray: ['idImagen', 'src', 'width', 'height'],
      }
      const archivos = {
        finalName: 'archivos',
        entity: 'archivo',
        fieldArray: ['idArchivo', 'name', 'path'],
      }
      const instalaciones = {
        finalName: 'instalaciones',
        entity: 'instalacion',
        fieldArray: ['idInstalacion', 'nombre', 'descripcion', 'imagen'],
      }
      const docentes = {
        finalName: 'docentes',
        entity: 'docente',
        fieldArray: ['idDocente', 'nombre', 'apellidos', 'departamento'],
      }
      const departamento = {
        finalName: 'departamentos',
        entity: 'departamento',
        fieldArray: ['idDepartamento', 'nombre', 'docente', 'archivo', 'link'],
      }
      const ofertaEducativa = {
        finalName: 'ofertaeducativas',
        entity: 'ofertaeducativa',
        fieldArray: ['idOfertaEducativa', 'siglas', 'nombre', 'descripcion', 'itemofertaeducativa'],
      }
      const itemOfertaEducativa = {
        finalName: 'itemofertaeducativas',
        entity: 'itemofertaeducativa',
        fieldArray: ['idItemOfertaEducativa', 'nombreOfertaEducativa'],
      }
      const ampa = {
        finalName: 'ampa',
        entity: 'ampa',
        fieldArray: ['idAmpa', 'contenido'],
      }
      const ogtipo = {
        finalName: 'ogtipo',
        entity: 'ogtipo',
        fieldArray: ['idOgTipo', 'nombreog'],
      }
      const ogtipoescolar = {
        finalName: 'ogtipoescolar',
        entity: 'ogtipoescolar',
        fieldArray: ['idOgTipoEscolar', 'nombreoe'],
      }
      const ogdirectivo = {
        finalName: 'ogdirectivo',
        entity: 'ogdirectivo',
        fieldArray: ['idOgDirectivo', 'ogtipo', 'docente'],
      }
      const ogescolar = {
        finalName: 'ogescolar',
        entity: 'ogescolar',
        fieldArray: ['idOGEscolar', 'ogtipoescolar', 'docente', 'nombre'],
      }
      const tipodocumentacion = {
        finalName: 'tipodocumentacion',
        entity: 'tipodocumentacion',
        fieldArray: ['idTipodocumentacion', 'nombredocumentacion'],
      }
      const documentacion = {
        finalName: 'documentacion',
        entity: 'documentacion',
        fieldArray: ['idDocumentacion', 'nombre', 'tipodocumentacion', 'archivo', 'fecha'],
      }
      const proyectos = {
        finalName: 'proyectos',
        entity: 'proyectos',
        fieldArray: ['idProyecto', 'nombreProyecto', 'contenidoProyecto', 'imagen', 'archivo'],
      }


      allMyRequest.push(cabeceras);
      allMyRequest.push(noticias);
      allMyRequest.push(anuncios);
      allMyRequest.push(orientacion);
      allMyRequest.push(imagenes);
      allMyRequest.push(archivos);
      allMyRequest.push(instalaciones);
      allMyRequest.push(docentes);
      allMyRequest.push(departamento);
      allMyRequest.push(ofertaEducativa);
      allMyRequest.push(itemOfertaEducativa);
      allMyRequest.push(ampa);
      allMyRequest.push(ogtipo);
      allMyRequest.push(ogtipoescolar);
      allMyRequest.push(ogdirectivo);
      allMyRequest.push(ogescolar);
      allMyRequest.push(tipodocumentacion);
      allMyRequest.push(documentacion);
      allMyRequest.push(proyectos);

      const allMyInfo = yield all(
        allMyRequest.map(currentReqData => loopGetEntity(currentReqData))
      );
      // remapping
      allMyInfo.forEach((item) => {
        const { data, finalName } = item;
        allDataMapped[finalName] = data;
      })


      const newProyectos = [];
      allDataMapped.proyectos.forEach((proyecto) => {
        const newProyecto = {
          ...proyecto,
        }
        let { imagen, archivo } = proyecto;
        imagen = imagen ? imagen.split(',') : [];
        archivo = archivo ? archivo.split(',') : [];
        const imagenes = [];
        if (imagen && imagen.length > 0) {
          imagen.forEach((key) => {
            const item2add = allDataMapped.imagenes.find(item => item.idImagen === key);
            if (item2add) {
              imagenes.push(item2add);
            }
          });
        }
        newProyecto.imagen = imagenes;
        const archivos = [];
        if (archivo && archivo.length > 0) {
          archivo.forEach((key) => {
            const item2add = allDataMapped.archivos.find(item => item.idArchivo === key);
            if (item2add) {
              archivos.push(item2add);
            }
          });
        }
        newProyecto.archivo = archivos;
        newProyectos.push(newProyecto);
      })
      allDataMapped.proyectos = newProyectos;

      yield put(uiActions.setMainInfo({
        data: allDataMapped,
        infoOp: 'success',
      }));
    }
  } catch (err) {
    console.log(err);
    yield put(uiActions.setMainInfo({
      data: null,
      infoOp: 'error',
    }));
  }
}
