import { getCookie } from 'utils/commonUtils';
import { KINDUSER_COOKIE_NAME } from '../../config/constants/constants';

export const types = {
  /* LOGIN */
  LOGIN: 'USER/LOGIN',
  LOGIN_OK: 'USER/LOGIN_OK',
  LOGIN_KO: 'USER/LOGIN_KO',
  /* MISCELANEOUS */
  CLOSE_SESSION: 'USER/CLOSE_SESSION',
  /* CHANGE PASSWORD */
  CHANGE_PASSWORD: 'USER/CHANGE_PASSWORD',
  CHANGE_PASSWORD_OK: 'USER/CHANGE_PASSWORD_OK',
  CHANGE_PASSWORD_KO: 'USER/CHANGE_PASSWORD_KO',
  /* SEND_EMAIL_RECOVER */
  SEND_EMAIL_RECOVER: 'USER/SEND_EMAIL_RECOVER',
  SEND_EMAIL_RECOVER_OK: 'USER/SEND_EMAIL_RECOVER_OK',
  SEND_EMAIL_RECOVER_KO: 'USER/SEND_EMAIL_RECOVER_KO',
  /* RESET_PASSWORD */
  RESET_PASSWORD: 'USER/RESET_PASSWORD',
  SET_RESET_PASSWORD: 'USER/SET_RESET_PASSWORD',
}

export const initialState = {
  /* LOGIN */
  logged: false,
  fetchingLogin: false,
  errorLogin: null,
  kindUser: getCookie(KINDUSER_COOKIE_NAME),
  /* CHANGE PASSWORD */
  changePasswordFetching: false,
  changePasswordError: false,
  changePasswordSuccess: false,
  /* SEND_EMAIL_RECOVER */
  sendEmailRecoverFetching: false,
  sendEmailRecoverError: false,
  sendEmailRecoverSuccess: false,
  /* REST PASSWORD */
  resetPassword: {
    operation: null,
    infoOp: null,
  },
}

const UIReducer = (state = initialState, action) => {
  switch (action.type) {
    /* LOGIN */
    case types.LOGIN: {
      return {
        ...state,
        logged: false,
        kindUser: null,
        fetchingLogin: true,
        errorLogin: null,
      }
    }
    case types.LOGIN_OK: {
      return {
        ...state,
        logged: true,
        kindUser: action.kindUser,
        fetchingLogin: false,
        errorLogin: null,
      }
    }
    case types.LOGIN_KO: {
      return {
        ...state,
        kindUser: null,
        logged: false,
        fetchingLogin: false,
        errorLogin: action.payload,
      }
    }
    case types.CLOSE_SESSION: {
      return {
        ...state,
        logged: false,
        fetchingLogin: false,
        errorLogin: null,
      }
    }
    /* CHANGE PASSWORD */
    case types.CHANGE_PASSWORD: {
      return {
        ...state,
        changePasswordFetching: true,
        changePasswordError: false,
        changePasswordSuccess: false,
      }
    }
    case types.CHANGE_PASSWORD_OK: {
      
      return {
        ...state,
        changePasswordFetching: false,
        changePasswordError: false,
        changePasswordSuccess: true,
      }
    }
    case types.CHANGE_PASSWORD_KO: {
      return {
        ...state,
        changePasswordFetching: false,
        changePasswordError: action.payload,
        changePasswordSuccess: false,
      }
    }
    /* SEND_EMAIL_RECOVER */
    case types.SEND_EMAIL_RECOVER: {
      return {
        ...state,
        sendEmailRecoverFetching: true,
        sendEmailRecoverError: false,
        sendEmailRecoverSuccess: false,
      }
    }
    case types.SEND_EMAIL_RECOVER_OK: {
      return {
        ...state,
        sendEmailRecoverFetching: false,
        sendEmailRecoverError: false,
        sendEmailRecoverSuccess: true,
      }
    }
    case types.SEND_EMAIL_RECOVER_KO: {
      return {
        ...state,
        sendEmailRecoverFetching: false,
        sendEmailRecoverError: action.payload,
        sendEmailRecoverSuccess: false,
      }
    }
    /* RESET_PASSWORD */
    case types.RESET_PASSWORD: {
      return {
        ...state,
        resetPassword: {
          infoOp: '',
          operation: 'loading',
        },
      }
    }
    case types.SET_RESET_PASSWORD: {
      return {
        ...state,
        resetPassword: action.result,
    }
    }
    default:
      return state
  }
}

export const actions = {
  /* LOGIN */
  login: params => ({ type: types.LOGIN, params }),
  loginOK: kindUser => ({ type: types.LOGIN_OK, kindUser }),
  loginKO: error => ({ type: types.LOGIN_KO, payload: error }),
  closeSession: () => ({ type: types.CLOSE_SESSION }),
  /* CHANGE PASSWORD */
  changePassword: params => ({ type: types.CHANGE_PASSWORD, params}),
  changePasswordOK: () => ({ type: types.CHANGE_PASSWORD_OK }),
  changePasswordKO: error => ({ type: types.CHANGE_PASSWORD_KO, payload: error }),
  /* SEND_EMAIL_RECOVER */
  sendEmailRecover: params => ({ type: types.SEND_EMAIL_RECOVER, params}),
  sendEmailRecoverOK: () => ({ type: types.SEND_EMAIL_RECOVER_OK }),
  sendEmailRecoverKO: error => ({ type: types.CHANGE_PASSWORD_KO, payload: error }),  
  /* RESET_PASSWORD */
  resetPassword: data => ({ type: types.RESET_PASSWORD, data }),
  setResetPassword: result => ({ type: types.SET_RESET_PASSWORD, result }),
}

export default UIReducer;