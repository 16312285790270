import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import burguerIcon from '../../assets/web/burguer.svg';
import './Menu.scss';

export const OPTIONS = [
  {
    label: 'Inicio',
    link: '/',
  },
  {
    label: 'Centro',
    link: '/centro/',
  },
  {
    label: 'Noticias',
    link: '/noticias/',
  },
  {
    label: 'Anuncios',
    link: '/anuncios/',
  },
  {
    label: 'Documentación',
    link: '/documentacion/',
  },
  {
    label: 'AMPA',
    link: '/ampa/',
  }
];


class Menu extends Component {
  state = {
    collapsed: true,
  }

  handleBurguer = () => {
    const { collapsed } = this.state;
    this.setState({
      collapsed: !collapsed,
    })
  }

  setCollapsed = () => {
    this.setState({
      collapsed: true,
    })
  }

  render() {
    const { collapsed } = this.state;
    const { visible, pathname } = this.props;
    if (!visible) {
      return null;
    }
  
    let currentActive = '/';
  
    OPTIONS.forEach((item) => {
      if (pathname.indexOf(item.link) >= 0) {
        currentActive = item.link;
      }
  
      if (pathname.indexOf('/noticia/') > -1) {
        currentActive = OPTIONS[2].link;
      }
  
      if (pathname.indexOf('/anuncio/') > -1) {
        currentActive = OPTIONS[3].link;
      }
    })
  
    return (
      <div key="Menu" className="Menu">
        <div className="container">
          {OPTIONS.map((item) => {
            let { label } = item;
            const active = item.link === currentActive;
            if (active) {
              label = `${label} {`;
            }
            const forceShowOnMobile = !collapsed ? ' forceShowOnMobile' : '';

            return (
              <div key={`section_${item.label}`} className={`section${active ? ' active' : forceShowOnMobile}`}>
                <Link to={item.link} className={`${active ? ' active' : ''}`} onClick={this.setCollapsed}>
                  <span className="regular">{label}</span>
                </Link>
                <button type="button" className="movIcon" onClick={this.handleBurguer}>
                  <img src={burguerIcon} alt="navigation-icon" />
                </button>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
} 

  


const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
})

export default connect(mapStateToProps, null)(Menu);
