import React from 'react';
import info from './info.json';
import logoVerde from '../../assets/images/logoVerde.svg';
import logoImei from '../../assets/imei.png';
import logoVicdesign from '../../assets/vicdesign.png';
import './Footer.scss';

const Footer = ({ visible }) => {
  if (!visible) {
    return null;
  }
  return (
    <div className="Footer">
      <div className="linksSection">
        <div className="container">
          <h2 className="tituloSeccion regular">ENLACES DE INTERÉS</h2>
          <div className="linksContainer">
            {info.map((section, index) => {
              return (
                <div key={`sectionlink${index.toString()}}`} className="group">
                  {section.map(item => (<a key={`link_footer-${item.label}`} href={item.link} className="regular externalLink" rel="noopener noreferrer" target="_blank">{item.label}</a>))}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="aboutSection">
        <div className="container">
          <div className="linksContainer">
            <div className="group">
              <img className="logo" src={logoVerde} alt="logo" />
            </div>
            <div className="group">
              <p className="regular margin">C/Maestro Manuel Casal nº2, 11.520 Rota | Cádiz</p>
              <a href="mailto:general@iesarroyohondo.es" className="regular externalLink" rel="noopener noreferrer" target="_blank">{'}'} general@iesarroyohondo.es</a>
            </div>
            <div className="group phoneGroup">
              <p className="regular margin">
                <span className="bold">Tel </span>
                <a className="externalLink" href="tel:+34 956 243 253">+34 956 243 253</a>
              </p>
              <p className="regular margin">
                <span className="bold">Fax </span>
                <span>+34 956 243 257</span>
              </p>
            </div>
            <div className="group">
              <a href="https://vicdesign.es" className="regular externalLink contact" rel="noopener noreferrer" target="_blank">
                <span>{"}"} Diseño </span>
                <img className="vicLogo" src={logoVicdesign} alt="vicdesign" />
              </a>
              <a href="https://imei.media" className="regular externalLink contact" rel="noopener noreferrer" target="_blank">
                <span>{"}"} Programación </span>
                <img className="imeiLogo" src={logoImei} alt="imei" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
